@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.main-content-wrapper-mend {
  * {
    font-weight: 500 !important;
  }
}

.main-content-wrapper-navis {
  * {
    font-weight: 300 !important;
  }

  & .title-navis {
    font-weight: 700 !important;
  }
}

.main-content-wrapper-digit {
  * {
    font-weight: 500 !important;
  }

  & .title-digit {
    font-weight: 600 !important;
  }
}

.main-content-wrapper-amazon {
  * {
    font-weight: 400 !important;
  }

  & .title-amazon {
    font-weight: 500 !important;
  }
}

.main-content-wrapper-zaurus {
  * {
    font-weight: 500 !important;
  }

  & .title-zaurus {
    font-weight: 600 !important;
  }
}
